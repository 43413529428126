
/* 
.home-content {
    background-image: url('../../public/hero.jpg');
    background-attachment: scroll;
    background-position: top;
} */

/* .home-content {
    background-image: url('../../public/hero.jpg');
} */

.home-title {
    min-height: 500px;
    justify-content: center;
    background-position: 50% 50%;
    background-image: url(https://fisichub.wordpress.com/wp-content/uploads/2024/10/milky-way-8153503_1280-2.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 10px 0;
}


.home-title h2{
    margin-bottom: 5px;
    margin-top: 25px;
    background-color: transparent;
    color: var(--text-hover);
}

.home-title p{
    margin: 0;
    justify-content: center;
    background-color: transparent;
    color: var(--text-hover);
}


.bg-hero{

}