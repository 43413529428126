.lezione {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    text-align: left;
  }
  .lezione h1 {
    text-align: center;
  }

  .lezione-content {
    width: 75%;

  }