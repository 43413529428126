.footer{
    width: 100vw;
    height: 200px;
    background-color: var(--footer);
    color: var(--footer-text);
    justify-content: space-evenly;
    
}
.footer div{
    background-color: inherit;
    transition: none;
}


.footer h3{
    background-color: inherit;
    transition: none;
}

.footer p{
    background-color: inherit;
    transition: none;
}

.footer img{
    background-color: inherit;
    transition: none;
}

.logo-title-footer-wrapper {
    transform: translate(50%, 15%);
    height: 100px;
}


.footer-logo-container{
    height: var(--navbar-height-desktop);
  display: flex;
  margin: 0.7em;
  justify-content: center;
}

.footer-logo-container img {
    height: 100%;
}

.footer-links-container{
    margin-top: 20px;
}
.footer-links-container div{
    margin-right: 100px;
    margin-top: 20px;
}
.footer-links-container p{
    margin: 0;
}

@media screen and (max-width : 800px) {
    .logo-title-footer-wrapper {
        transform: none;
        margin-left: 0; 
        margin-top: 20px;
    }

    .footer-links-container{
        margin-top: 0;
    }

    .footer-links-container div{
        margin-right: 0;
        margin-top: 40px;
    }
    
}