/* Stile per la pagina Didattica */
.didattica-page {
    text-align: center;
  }
  
  .didattica-page h1 {
    font-size: 48px;
  }

  .subject-buttons-container{
    width: 98vw;
  }
  /* Bottone principale per Matematica e Fisica */
  .button-subjet {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    background-color: var(--button);
  }
  
  /* Dropdown menu */
  .dropdown {
    position: relative;
    display: inline-block;
    width: 25%;
    margin: 0 3.5rem;
  }
  
  .dropdown-menu {
    display: block;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding-left: 0;
    width: 100%;
  }
  
  .dropdown-menu li {
    list-style: none;
    padding: 12px 16px;
    color: black;
    text-decoration: none;
    display: block;
  }

  .dropdown-menu li:hover {
    background-color: var(--secondary);
    cursor: pointer;
  }
  
.subject-choice {
  background-color: var(--secondary);
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 20px;
}

.subject-choice div {
  background-color: var(--secondary);
}

.recent-articles {
  justify-content: center;
  /* min-height: 30vh; */
}

.recent-article-title {
  margin-top: 20px;
  font-size: 1.3rem;
}

.recent-articles-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 95%;
}
.recent-post{
  cursor: pointer;
  width: 25%;
  min-width: 200px;
  margin-top: 10px;
  border: 1px solid #48676d; /* Colore del bordo */
  border-radius: 8px; /* Angoli leggermente arrotondati */
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.5); /* Rilievo */
  padding: 10px; /* Margine interno */
  /* transition: transform 0.2s, box-shadow 0.2s; /* Animazione al passaggio del mouse */ 

}

.recent-post:hover{
  transform: translateY(-4px); /* Leggero movimento verso l'alto */
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3), -3px -3px 7px rgba(255, 255, 255, 0.6); /* Effetto hover */
}
.recent-post img {
  width: 150px;
}

.recent-post-title {
  text-decoration: underline;
}

@media screen and (max-width : 800px){
  .dropdown {
    width:50%;
  }

  .dropdown-menu {
    width: 55vw;
  }
}