.post {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
   /*  transition: box-shadow 0.3s ease; */
    width: 50%;
}

.post img {
    width: 7%;
    margin-right: 5%;
}

.post:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.post-image {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 15px;
}

.post-title {
    font-family: 'Arial', sans-serif;
    titransion: color 0.3s ease;
    width: 70%;
    text-align: left;
}

.post-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.subject-list{
    margin-top: 10px;
}

.subject-name {
    font-size: large;
}

@media screen and (max-width : 800px){
    .post{
        width: 95%;
    }

    .post img {
        width: 15%;
    }
}