
.simulazioni-wrapper{
    display: flex;
    justify-content: center;
}

.simulazioni-container {
    display: flex;
    flex-wrap: wrap;
    width: 91%;
    justify-content: space-evenly;
}


.simulazione-thumbnail {
    width: 25%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    border: 1px solid #48676d; /* Colore del bordo */
    border-radius: 8px; /* Angoli leggermente arrotondati */
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.5); /* Rilievo */
    /* transition: transform 0.2s, box-shadow 0.2s; /* Animazione al passaggio del mouse */ 
}

.simulazione-thumbnail:hover{
    transform: translateY(-4px); /* Leggero movimento verso l'alto */
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3), -3px -3px 7px rgba(255, 255, 255, 0.6); /* Effetto hover */
  }

.simulazione-thumbnail img {
    width: 100%;
    height: 250px;
}

.simulazione-thumbnail div {
    font-size: 1.25rem;
    background-color: transparent;
}


@media screen and (max-width : 800px) {
    .simulazione-thumbnail {
        width: 40%;
    }
    .simulazione-thumbnail img {
        height: 130px;
    }
}