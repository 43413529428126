/* Navbar principale */
.navbar {
  top: 0;
  padding: 10px;  /* Navbar più spessa */
  color: var(--text);
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  width: 90vw;
}

.logo-container { 
  height: var(--navbar-height-desktop);
  display: flex;
  margin: 0.7em;
  cursor: pointer;
}

.logo-img img{
  height: 100%;
}

/* Stile del logo */
.logo-container h1 {
  font-size: 20px;
  margin: 0;
  align-self: center;
  margin-left: 15px;
  color: var(--text)
}

/* Stile dei link di navigazione */
.nav-links {
  list-style: none;
  margin: 0 30px 0 0;
  display: flex;
  flex: 1; /* Per centrare */
  justify-content: flex-end;
  height: var(--navbar-height-desktop);
}

/* Stile degli elementi della lista */
.nav-links li {
  align-self: center;
  height: 50px;
  align-content: center;
  color: var(--text);
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
  width: 100px;
  border-radius: 10px;
}

.nav-links li:hover{
  background-color: var(--button-hover);
  color: balck;
}

.navbar-container::after {
  content: '';
  display: block;
  width: 90%;
  transform: translate(5%, 0);
  height: 1px;
  background-color: #a89f94; /* Colore marrone chiaro */

}

.menu-icon{
  display: none;
}

.mobile-menu {
  display: none;
}

.didattica-dropdown-menu {
  display: block;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding-left: 0;
  margin-top: 20px;
}

.didattica-dropdown-menu li {
  list-style: none;
  padding: 12px 16px;
  color: black;
  text-decoration: none;
  display: block;
  width: 100%;
  margin: 0;
  border-radius: 0px;
}

.didattica-dropdown-menu li:hover {
  background-color: var(--secondary);
  cursor: pointer;
}
.desktop-dark-toggle {
  margin-top: 5px;
}
.mobile-dark-toggle svg{
  background-color: inherit;
}
.mobile-dark-toggle {
  background-color: inherit;
  transition: none;
}


/* Mobile styles */

@media screen and (max-width : 800px){
  .nav-links {
    display: none;
  }
  .desktop-dark-toggle{
    display: none;
  }

  .navbar{
    justify-content: center;
  }

  .logo-container { 
    margin-left: auto;
  }

  .logo-container h1 {
    display: none;
  }

  .menu-icon {
    display: flex;
    margin-left: 20px;
  }

  .mobile-menu {
    display: block;
    height: 100vh;
    background:var(--mobile-menu);
    top:0;
    position: fixed;
    left: -100%; /* Fuori dalla vista inizialmente */
    width: 100vw;
    /* transition: ease-in-out 500ms all; /* Animazione per far scorrere il menù */ 
    z-index: 999;
  }

  .mobile-menu svg {
    transition: none;
  }

  .mobile-menu-translate {
    left: 0;
  }

  .mobile-menu-close-icon {
    position: absolute;
    top: 5%;
    left: 75%;
    background-color: inherit;
  }

  .mobile-nav-links {
    list-style: none;
    margin-top: 30%;
    background-color: inherit;
    transition: none;
  }

  .mobile-nav-sub-links{
    list-style: none;
    background-color: inherit;
  }

  .mobile-nav-links li {
    text-decoration: none;
    color: var(--mobile-menu-text);
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 75%;
    font-size: 1.5rem;
    transition: none;
  }

  .mobile-nav-sub-links li {
    text-decoration: none;
    color: var(--mobile-menu-text);
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 75%;
    font-size: 1.5rem;
  }

  .mobile-nav-sub-links li::after{
    content: none;
  }
  
  .mobile-nav-links li::after{
    content: '';
    display: block;
    width: 90%;
    transform: translate(5%, 0);
    height: 1px;
    background-color: var(--mobile-menu-text); /* Colore marrone chiaro */
  }
}