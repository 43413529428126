:root{

  --navbar-height-desktop: 60px;
}
:root.light-theme {
 /*LIGHT THEME*/
 --background: #EEE4DA;
 --secondary: #48676d;
 --text: #48676d; 
 --text-hover: #e2ffff; 
 --page-title-color: #48676d;

 --button: #6d8681;
 --button-hover: #97a598;
 --button-text: #2f4858;
 --button-hover-text: #2f4858; 
 --footer: #48676d;
 --footer-text: #97a598;

 --mobile-menu: #48676d;
 --mobile-menu-text: #EEE4DA;


}

:root.dark-theme{
  /*DARK THEME*/
  --background: #2f4f4f;
  --secondary: #00c194;
  --text: #00c194; 
  --text-hover: #e2ffff; 
  --page-title-color: #fff7d6;

  --button: #357367;
  --button-hover: #fff7d6;
  --button-text: #e2ffff;
  --button-hover-text: #00c194;
  --footer: #1e3333;
  --footer-text: #00c194;

  --mobile-menu: #1e3333;
  --mobile-menu-text: #00c194;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  font-family: "Cardo", serif;
  margin: 0;
  padding: 0;
  background-color: white;
  width: 100vw;
}


*{
  background-color: var(--background);
  transition: ease-in-out 500ms all;
}

.content {
  min-height: 75vh;
  width: 100vw;
}

.button-primary {
  background-color: var(--button);
  color: var(--button-text)
}

.button-primary:hover{
  background-color: var(--button-hover);
  color: var(--button-hover-text)
}


h2{
  color: var(--page-title-color);
  font-weight: normal;
  font-size: 40px;
}

canvas {
  margin: 10px 0;
}


.flex-column{
  display: flex;
  flex-direction: column;
}


.flex-row{
  display: flex;
  flex-direction: row;
}

.align-items-center {
  align-items: center;
}

.bg-primary{
  background-color: var(--background);
}

.bg-secondary{
  background-color: var(--secondary);
}

.margin-0 {
  margin: 0 !important;
}

.division-bar {
  content: '';
  display: block;
  width: 90%;
  transform: translate(5%, 0);
  height: 1px;
  background-color: #a89f94; /* Colore marrone chiaro */
}