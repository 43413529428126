.questionari-list{
    justify-content: space-evenly;
    font-size: 1.3rem;
    flex-wrap: wrap;
}

.questionari-list img{
    width: 200px;
}

.questionario-wrapper {
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

.questionari-list a{
    margin-top: 20px;
}