.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
    top:200px;

  }
  
  .loader-circle {
    animation: spin 2s linear infinite, dash 1.5s ease-in-out infinite;
    transform-origin: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 250; /* Anello scompare progressivamente */
    }
    50% {
      stroke-dashoffset: 125; /* Mezzo anello visibile */
    }
    100% {
      stroke-dashoffset: 0; /* Anello completato */
    }
  }